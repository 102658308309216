<template>
  <div>
    <div class="whiteStyle"></div>
    <div class="h5-wrapper" id="h5-wrapper">
      <div class="top-bg" v-if="isShow == true">
        <div class="company-name">
          {{ riskInfo.companyName }}
        </div>
        <div class="date">{{ riskInfo.startTime }}至{{ riskInfo.endTime }}</div>
      </div>

      <div class="top-bg" v-else>
        <div class="company-name">
          {{ riskInfo.companyName }}
        </div>
        <!-- <div class="company-name" v-else>
          {{ basicInfo.desensitizationCompanyName }}
        </div> -->

        <div class="date">{{ riskInfo.startTime }}至{{ riskInfo.endTime }}</div>
      </div>
      <div class="main-section">
        <div class="share" v-if="isShow">
          <div class="wrapper">
            <p @click="dialogVisible = true">分享</p>

            <van-popup v-model="dialogVisible" closeable :style="{ width: '360px' }">
              <van-form ref="form" validate-first class="content">
                <div class="title">安全提示</div>
                <div class="text">
                  检测报告涉及企业隐私数据，请谨慎分享，分享后对方查看报告需要输入校验码。
                </div>
                <div class="form-control">
                  <van-field
                    name="验证码有效期"
                    label="验证码有效期"
                    :rules="[
                      { validator: rules.expireHours, message: '请输入1-48的整数' },
                    ]"
                    v-model.number="form.expireHours"
                    :border="true"
                  >
                  </van-field>
                  <div class="unit">小时</div>
                </div>

                <div class="tuomin">
                  <van-checkbox
                    v-model="form.desensitization"
                    shape="square"
                    v-if="desensitizationAvailable"
                    >对报告中关键的公司名称、税号、地址进行脱敏
                  </van-checkbox>
                </div>
                <div class="dialog-footer">
                  <textarea v-model="shareText" id="input" class="textarea" />
                  <button class="btn btn-ok" @click="handleConfirmClick">
                    复制链接及验证码
                  </button>
                </div>
              </van-form>
            </van-popup>
          </div>
        </div>
        <div class="top-scores">
          <div class="top-score">
            <div class="score dark_grey">{{ riskInfo.total }}</div>
            <div class="score-name">检测总项</div>
          </div>
          <div class="top-score">
            <div class="score dark_grey">{{ riskInfo.active }}</div>
            <div class="score-name">风险项</div>
          </div>
          <div class="top-score">
            <div class="score">{{ getRiskLevelLabel(riskInfo.riskLevel) }}</div>
            <div class="score-name">风险等级</div>
          </div>
        </div>
        <div class="statistic-section">
          <div class="date-unit-wrapper">
            <div class="date">
              {{ businessInfo.startTime }}至{{ businessInfo.endTime }}
            </div>
            <div class="unit">单位：万元</div>
          </div>
          <div class="statistic-items">
            <div class="statistic-item">
              <div class="statistic-item-value">{{ businessInfo.yysr }}</div>
              <div class="statistic-item-name">营业收入</div>
            </div>
            <div class="statistic-item">
              <div class="statistic-item-value">{{ businessInfo.yycb }}</div>
              <div class="statistic-item-name">营业成本</div>
            </div>
            <div class="statistic-item">
              <div class="statistic-item-value">{{ businessInfo.mlr }}</div>
              <div class="statistic-item-name">毛利额</div>
            </div>
            <div class="statistic-item">
              <div class="statistic-item-value">{{ businessInfo.jlr }}</div>
              <div class="statistic-item-name">净利润</div>
            </div>
          </div>
        </div>
        <div class="entries-section">
          <router-link :to="{ name: 'IndustryComparison' }" class="entry-box">
            <div class="entry-name">
              <svg class="icon entry-icon" aria-hidden="true">
                <use xlink:href="#iconhangyeduibi"></use>
              </svg>
              行业对比
            </div>
            <div class="entry-text">和同行比，和过往比</div>
          </router-link>
          <router-link :to="{ name: 'financeAnalysis' }" class="entry-box">
            <div class="entry-name">
              <svg class="icon entry-icon" aria-hidden="true">
                <use xlink:href="#iconcaiwufenxi"></use>
              </svg>
              财务分析
            </div>
            <div class="entry-text">3年账务，一目了然</div>
          </router-link>
          <router-link
            :to="{ name: 'OneKeyWatchInvoice' }"
            class="entry-box"
            style="margin-bottom: 0"
          >
            <div class="entry-name">
              <svg class="icon entry-icon" aria-hidden="true">
                <use xlink:href="#iconyijiankanpiao"></use>
              </svg>
              一键看票
            </div>
            <div class="entry-text">进/销项发票，问题发票</div>
          </router-link>
          <router-link
            :to="{ name: 'TaxAnalysis' }"
            class="entry-box"
            style="margin-bottom: 0"
          >
            <div class="entry-name">
              <svg class="icon entry-icon" aria-hidden="true">
                <use xlink:href="#iconnashuifenxi"></use>
              </svg>
              纳税分析
            </div>
            <div class="entry-text">税额、税目</div>
          </router-link>
        </div>
        <div class="risk-project-section">
          <div class="tool-bar">
            <div class="title">风险项目列表</div>
            <div class="btns">
              <van-dropdown-menu class="select">
                <van-dropdown-item
                  v-model="riskFilter"
                  @change="handleRiskFilterChange"
                  :options="riskOptions"
                />
              </van-dropdown-menu>
            </div>
          </div>
          <BossTable
            :loaded="loaded"
            :data="riskList"
            class="risk-project-table"
          ></BossTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BossTable from "./component/BossTable.vue";
import "./bossWatchReport.styl";
import { Dialog, Button } from "vant";
import { getBusinessInfo, getRiskInfo, getRiskList } from "../../../api/bossWatchReport";
import Copy from "copy-to-clipboard";
import {
  createShareCode,
  getDesensitizationStatus,
  h5GetResultBasicInfo,
} from "../../../api/api";

export default {
  name: "BossWatchReport",
  components: { BossTable },
  props: {
    uuid: String,
  },
  data() {
    return {
      loaded: false,
      businessInfo: {},
      riskInfo: {},
      riskList: {},
      riskFilter: 0,
      riskOptions: [
        { text: "全部", value: 0 },
        { text: "已触警", value: 1 },
        { text: "未触警", value: -1 },
      ],

      stepper: 1,

      dialogVisible: false,
      rules: {
        expireHours(value) {
          return Number.isInteger(value) && value >= 1 && value <= 48;
        },
      },
      form: {
        desensitization: false,
        shareCode: "",
        expireHours: 1,
      },
      desensitizationAvailable: false,
      shareText: "点击下方按钮获取分享链接",
      basicInfo: {},
      isShow: false,
      companyNameTwo: "",
    };
  },

  methods: {
    handleRiskFilterChange() {
      this.loadRiskList();
    },
    async loadRiskList() {
      this.loaded = false;
      this.riskList.body = [];
      const params = {
        uuid: this.uuid,
      };
      const _this = this;
      if (this.riskFilter == 1) {
        params.active = true;
      } else if (this.riskFilter == -1) {
        params.active = false;
      }
      const resRiskList = await getRiskList(params);
      if (resRiskList.data) {
        this.riskList.body = resRiskList.data.items.map((row) => {
          const ret = [];
          if (row.active) {
            ret.push({ content: row.order, click(e) {} });
            ret.push({
              content: row.name,
              click(e) {
                _this.$router.push({
                  name: "ReportDetails",
                  params: {
                    name: row.name,
                  },
                });
              },
            });
            ret.push({ content: "是" });
          } else {
            ret.push({ content: row.order });
            ret.push({ content: row.name });
            ret.push({ content: "否" });
          }

          return ret;
        });
        this.loaded = true;
      } else {
        this.riskList.body = [];
        this.loaded = true;
      }
    },
    async loadData() {
      const resBusinessInfo = await getBusinessInfo(this.uuid);

      this.businessInfo = resBusinessInfo.data;
      let desensitizationStatus = this.$route.query.desensitization || 0;
      const resRiskInfo = await getRiskInfo(this.uuid, desensitizationStatus);
      this.riskInfo = resRiskInfo.data;
    },
    getRiskLevelLabel(index) {
      const riskLevel = ["低", "中低", "中", "中高", "高"];
      return riskLevel[index];
    },

    async handleConfirmClick() {
      try {
        await this.$refs.form.validate();
      } catch (e) {
        return;
      }

      const token = localStorage.getItem("authorization");
      const res = await createShareCode(this.uuid, this.nsrsbh, this.form.expireHours);

      let props = this.$router.resolve({
        name: "result-head-share2",
        params: {
          uuid: this.$route.params.uuid,
          shareCode: "null",
          checkId: res.data.urlCheck,
        },
        query: {
          ...this.$route.query,
          desensitization: this.form.desensitization ? 1 : 0,
        },
      });

      const sharedUrl = new URL(props.href, window.location.href).href;
      const companyName = this.form.desensitization
        ? this.basicInfo.desensitizationCompanyName
        : this.basicInfo.companyName;

      if (res.msgCode == 1) {
        this.shareText = `报告名称：${companyName}-老板看报告
        报告地址：${sharedUrl}
        校验码：${res.data.checkCode}
        有效期截止：${res.data.effectiveDate}`;
        setTimeout(() => {
          let copyText = document.querySelector("#input");
          copyText.select();
          document.execCommand("copy");
          this.$toast.success("复制成功");
        });
        this.dialogVisible = false;
      } else {
        this.error = true;
      }
    },
  },
  computed: {},
  watch: {
    form: {
      handler() {
        this.shareText = "点击下方按钮获取分享链接";
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.$route.query.desensitization) {
      this.isShow = false;
    }
    const resBasicInfo = await h5GetResultBasicInfo(this.$route.params.uuid);
    this.basicInfo = resBasicInfo.data;
    if (this.$route.query.token) {
      localStorage.setItem("authorization", this.$route.query.token);
    }
    this.loadData();
    this.loadRiskList();
    this.riskList = {
      header: [
        [
          {
            content: "序号",
          },
          {
            content: "本企业",
          },
          {
            content: "是否有风险",
          },
        ],
      ],
      body: [
        //[
        // {
        //   "colspan": 1,
        //   "rowspan": 1,
        //   "align": "center",
        //   "content": "增值税税负率",
        //   click(e) {
        //     console.log(e)
        //   }
        // }
        //    ]
      ],
    };

    //脱敏报告是否可用
    // const desensitizationRes = await getDesensitizationStatus({ uuid: this.uuid });
    // if (desensitizationRes.data) {
    //   this.desensitizationAvailable = true;
    //   this.form.desensitization = true;
    // } else {
    //   this.form.desensitization = false;
    //   this.desensitizationAvailable = false;
    // }
  },
};
</script>

<style lang="stylus" scoped>

.h5-wrapper {
  width: 100%;
  padding-bottom 0px
  .share{
    z-index: 999;
    position: absolute;
    top: -16px;
    background: #fff;
    right: 23px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0 auto;
    border: 1px solid #c32d2d;
    p{
      line-height: 40px;
      font-weight: 600;
      font-size: 12px;
      color: #4ec0cd;
      margin: 0px 4px;
      >>>.van-button--normal{
        padding 0;
      }
    }
  }
  .top-bg {
    padding 0 20px
    box-sizing border-box
    width: 100%
    height: 22vw;
    display flex
    align-items center
    justify-content space-between
    background: linear-gradient(48deg, #2774FF 50%, #2673FE 100%);

    .company-name {
      font-weight: 600;
      color: #FFFFFF;
      height: 48px;
      font-size: 16px;
      line-height 1.5;
      max-width 50vw
      position relative
      top -5px
      text-align left
      display flex
      align-items center
    }

    .date {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 42px;
      position relative
      top -5px
    }
  }

  .main-section {
    width: 100%;
    background: #fff;
    border-radius 25px
    position relative;
    top -5vw;
    box-sizing border-box
    padding 5vw

    .top-scores {
      display flex
      flex-direction row
      justify-content space-between

      border-bottom: 1px dashed #7585A2;
      padding-bottom 24px

      .top-score {
        display flex
        align-items center
        justify-content space-between
        width: 85px;
        flex-direction column
        margin-top 5px

        .score {
          font-size: 24px;
          font-weight: 600;
          color: #F5222D
          line-height: 36px;
          text-align center
        }
        .dark_grey{
          color: #1D2129

        }
        .score-name {
          margin-top 4px
          font-size: 14px;
          font-weight: 400;
          color: #4E5969;
          line-height: 21px;
          text-align center
        }
      }

    }

    .statistic-section {
      border-bottom: 1px dashed #7585A2;
      padding-bottom 24px

      .date-unit-wrapper {
        margin-top 20px
        font-size: 14px;
        font-weight: 400;
        color: #7585A2;
        line-height: 21px;
        display flex
        align-items center
        justify-content space-between

        .date {

        }

        .unit {

        }
      }

      .statistic-items {
        display flex
        flex-direction row
        justify-content space-between
        margin-top 16px
        background: #E5F0FF;
        border-radius: 4px;
        padding 12px
        box-sizing border-box
        flex-wrap wrap
        >div:first-child{
          margin-bottom 20px

        }
        .statistic-item {
          //margin-bottom 20px
          width: 50%;
          height:50%;
          display flex
          justify-content space-between
          flex-direction column
          flex-shrink 0
          text-align left

          .statistic-item-value {
            font-size: 20px;
            font-weight: 600;
            color: #1D2129;
            line-height: 28px;
          }

          .statistic-item-name {
            margin-top 4px
            font-size: 14px;
            font-weight: 400;
          }

        }
      }

    }

    .entries-section {
      border-bottom: 1px dashed #7585A2;
      padding-bottom 20px
      display flex
      flex-direction row
      justify-content space-between
      margin-top 20px
      flex-wrap wrap

      .entry-box {
        background: #E5F0FF;
        border-radius: 4px;
        margin-bottom 20px
        width: 42vw;
        display flex
        justify-content flex-start
        flex-direction column
        flex-shrink 0
        text-align left
        padding 12px
        box-sizing border-box

        .entry-name {
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1D2129;
          line-height: 28px;

          .entry-icon {
            width: 25px;
            height: 25px;
            vertical-align: middle;
            margin-right: 4px;
          }
        }

        .entry-text {
          font-size: 12px;
          font-weight: 400;
          color: #4E5969;
          line-height: 18px;
          margin-top 8px
        }
      }
    }

    .risk-project-section {
      //border-bottom: 1px dashed #7585A2;
      padding-bottom 20px
      display flex
      flex-direction row
      justify-content space-between
      margin-top 20px
      flex-wrap wrap

      .tool-bar {
        display flex
        width 100%
        justify-content space-between
        align-items: center

        .title {
          font-size: 16px;
          font-weight: 400;
          color: #1D2129;
          line-height: 24px;
        }


      }

      .risk-project-table {
        margin-top 20px
      }

    }
  }
}
@media screen and (min-width:600px) and (max-width: 1300px){
  .whiteStyle{
    height 10px;
  }
  .h5-wrapper {
    width: 375px;
    margin:0px auto;
    height:600px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
    .top-bg {
      padding 0 20px
      box-sizing border-box
      width: 100%
      height: 10vw;
      display flex
      align-items center
      justify-content space-between
      background: linear-gradient(48deg, #2774FF 50%, #2673FE 100%);

      .company-name {
        font-weight: 600;
        color: #FFFFFF;
        height: 48px;
        font-size: 16px;
        line-height 1.5;
        max-width 16vw
        position relative
        top -36px
        text-align left
        display flex
        align-items center
      }

      .date {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
        position relative
        top -36px
      }
    }

    .main-section {
      width: 100%;
      background: #fff;
      border-radius 25px
      position relative;
      top -5vw;
      box-sizing border-box
      padding 1.1vw

      .top-scores {
        display flex
        flex-direction row
        justify-content space-between

        border-bottom: 1px dashed #7585A2;
        padding-bottom 24px

        .top-score {
          display flex
          align-items center
          justify-content space-between
          width: 85px;
          flex-direction column
          margin-top 5px

          .score {
            font-size: 24px;
            font-weight: 600;
            color: #F5222D
            line-height: 36px;
            text-align center
          }
          .dark_grey{
            color: #1D2129

          }
          .score-name {
            margin-top 4px
            font-size: 14px;
            font-weight: 400;
            color: #4E5969;
            line-height: 21px;
            text-align center
          }
        }

      }

      .statistic-section {
        border-bottom: 1px dashed #7585A2;
        padding-bottom 24px

        .date-unit-wrapper {
          margin-top 20px
          font-size: 14px;
          font-weight: 400;
          color: #7585A2;
          line-height: 21px;
          display flex
          align-items center
          justify-content space-between
        }

        .statistic-items {
          display flex
          flex-direction row
          justify-content space-between
          margin-top 16px
          background: #E5F0FF;
          border-radius: 4px;
          padding 12px
          box-sizing border-box
          flex-wrap wrap
          >div:first-child{
            margin-bottom 20px

          }
          .statistic-item {
            //margin-bottom 20px
            width: 50%;
            height:50%;
            display flex
            justify-content space-between
            flex-direction column
            flex-shrink 0
            text-align left

            .statistic-item-value {
              font-size: 20px;
              font-weight: 600;
              color: #1D2129;
              line-height: 28px;
            }

            .statistic-item-name {
              margin-top 4px
              font-size: 14px;
              font-weight: 400;
            }

          }
        }

      }

      .entries-section {
        border-bottom: 1px dashed #7585A2;
        padding-bottom 20px
        display flex
        flex-direction row
        justify-content space-between
        margin-top 20px
        flex-wrap wrap

        .entry-box {
          background: #E5F0FF;
          border-radius: 4px;
          margin-bottom 0.2rem;
          //width: 8vw;
          width: 47%;
          display flex
          justify-content flex-start
          flex-direction column
          flex-shrink 0
          text-align left
          padding 0.12rem
          box-sizing border-box

          .entry-name {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #1D2129;
            line-height: 28px;

            .entry-icon {
              width: 25px;
              height: 25px;
              vertical-align: middle;
              margin-right: 4px;
            }
          }

          .entry-text {
            font-size: 12px;
            font-weight: 400;
            color: #4E5969;
            line-height: 18px;
            margin-top 8px
          }
        }
      }

      .risk-project-section {
        //border-bottom: 1px dashed #7585A2;
        padding-bottom 20px
        display flex
        flex-direction row
        justify-content space-between
        margin-top 20px
        flex-wrap wrap

        .tool-bar {
          display flex
          width 100%
          justify-content space-between
          align-items: center

          .title {
            font-size: 16px;
            font-weight: 400;
            color: #1D2129;
            line-height: 24px;
          }


        }

        .risk-project-table {
          margin-top 20px


        }

      }
    }
  }
  >>>.main-wrapper{
    width: 375px;
    min-height: 0;
  }
  >>>.van-popup van-popup--top van-dropdown-item__content{
    margin 0 auto;
    background: pink;
  }
  >>>.van-popup--top{
    width:20%;
    margin-left: 40%;
  }
}

@media screen and (min-width:1300px) and (max-width: 2000px){
  .whiteStyle{
    height 40px;
  }
  .h5-wrapper {
    width: 375px;
    margin:0px auto;
    height:800px;
    overflow: auto;
    padding-bottom 0px
    box-shadow: 3px 2px 11px 2px #dedede;
    .top-bg {
      padding 0 20px
      box-sizing border-box
      width: 100%
      height: 10vw;
      display flex
      align-items center
      justify-content space-between
      background: linear-gradient(48deg, #2774FF 50%, #2673FE 100%);

      .company-name {
        font-weight: 600;
        color: #FFFFFF;
        height: 48px;
        font-size: 16px;
        line-height 1.5;
        max-width 10vw
        position relative
        top -36px
        text-align left
        display flex
        align-items center
      }

      .date {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 21px;
        position relative
        top -36px
      }
    }

    .main-section {
      width: 100%;
      background: #fff;
      border-radius 25px
      position relative;
      top -5vw;
      box-sizing border-box
      padding 1.1vw

      .top-scores {
        display flex
        flex-direction row
        justify-content space-between

        border-bottom: 1px dashed #7585A2;
        padding-bottom 24px

        .top-score {
          display flex
          align-items center
          justify-content space-between
          width: 85px;
          flex-direction column
          margin-top 5px

          .score {
            font-size: 24px;
            font-weight: 600;
            color: #F5222D
            line-height: 36px;
            text-align center
          }
          .dark_grey{
            color: #1D2129

          }
          .score-name {
            margin-top 4px
            font-size: 14px;
            font-weight: 400;
            color: #4E5969;
            line-height: 21px;
            text-align center
          }
        }

      }

      .statistic-section {
        border-bottom: 1px dashed #7585A2;
        padding-bottom 24px

        .date-unit-wrapper {
          margin-top 20px
          font-size: 14px;
          font-weight: 400;
          color: #7585A2;
          line-height: 21px;
          display flex
          align-items center
          justify-content space-between
        }

        .statistic-items {
          display flex
          flex-direction row
          justify-content space-between
          margin-top 16px
          background: #E5F0FF;
          border-radius: 4px;
          padding 12px
          box-sizing border-box
          flex-wrap wrap
          >div:first-child{
            margin-bottom 20px

          }
          .statistic-item {
            //margin-bottom 20px
            width: 50%;
            height:50%;
            display flex
            justify-content space-between
            flex-direction column
            flex-shrink 0
            text-align left

            .statistic-item-value {
              font-size: 20px;
              font-weight: 600;
              color: #1D2129;
              line-height: 28px;
            }

            .statistic-item-name {
              margin-top 4px
              font-size: 14px;
              font-weight: 400;
            }

          }
        }

      }

      .entries-section {
        border-bottom: 1px dashed #7585A2;
        padding-bottom 20px
        display flex
        flex-direction row
        justify-content space-between
        margin-top 20px
        flex-wrap wrap

        .entry-box {
          background: #E5F0FF;
          border-radius: 4px;
          margin-bottom 0.2rem;
          //width: 8vw;
          width: 47%;
          display flex
          justify-content flex-start
          flex-direction column
          flex-shrink 0
          text-align left
          padding 0.12rem
          box-sizing border-box

          .entry-name {
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #1D2129;
            line-height: 28px;

            .entry-icon {
              width: 25px;
              height: 25px;
              vertical-align: middle;
              margin-right: 4px;
            }
          }

          .entry-text {
            font-size: 12px;
            font-weight: 400;
            color: #4E5969;
            line-height: 18px;
            margin-top 8px
          }
        }
      }

      .risk-project-section {
        //border-bottom: 1px dashed #7585A2;
        padding-bottom 20px
        display flex
        flex-direction row
        justify-content space-between
        margin-top 20px
        flex-wrap wrap

        .tool-bar {
          display flex
          width 100%
          justify-content space-between
          align-items: center

          .title {
            font-size: 16px;
            font-weight: 400;
            color: #1D2129;
            line-height: 24px;
          }


        }

        .risk-project-table {
          margin-top 20px


        }

      }
    }
  }
  >>>.main-wrapper{
    width: 375px;
    min-height: 0;
  }
  >>>.van-popup van-popup--top van-dropdown-item__content{
    margin 0 auto;
    background: pink;
  }
  >>>.van-popup--top{
    width:20%;
    margin-left: 40%;
  }
}
>>>.van-dialog__content{
padding 5px 15px;
margin 0 auto;
}
>>>.van-dialog__header{
  color:black;
}
.tooTip{
  color: gray
  font-size: 13px;
  line-height: 17px;
}
>>>.van-cell{
  padding: 10px 0px;
}
.checked{
  margin-left: 10px;
  color: #2774ff;
  font-weight: bold;
}
>>>.van-checkbox__icon--checked .van-icon{
  background-color: #2774ff;
}
.copyBtn{
  background: linear-gradient(88deg, #99b8f0 2%, #0349c8 100%);
  height: 0.4rem;
  border-radius: 8px;
  text-align: center;
  margin: 0 auto;
  line-height: 0.4rem;
  color: #fff;
}
.textarea{
    width: 100%;
    //height: 70px;
    height: 170px;

    margin: 5px 0 24px;
    border-radius: 5px;
    border: 1px solid #dedede;
    //overflow hidden
    //text-overflow: ellipsis:
    h5{
      margin 5px;
    }
}
>>>.van-dialog__confirm, .van-dialog__confirm:active {
  background: #2774ff;
}
//
.wrapper {
  display: flex;
  width 100%

  .icon {
    color: rgb(250, 173, 20);
    font-size: 32px;
  }

  .content {
    //font-size 0.28rem
    padding 39px
    padding-top 17px
    padding-bottom 25px

    .title {
      height: 24px;
      font-size: 16px;
      text-align center
      font-weight: 600;
      color: #000000;
      line-height: 24px;
    }

    .text {
      margin-top: 8px;
      font-size: 14px;
      color: #666;
      line-height: 1.5;
      margin-bottom 24px
    }
    .form-control{
      position relative;
      display flex;
      >>>.van-field__control{
        border 1px solid #ccc
        padding 0 5px
        margin-right 5px
      }

      .unit{
        width 100px
        line-height 24px
        font-size 14px
        color #646566
      }
    }

    .tuomin {
      margin-top 17px
      margin-bottom 10px
    }

    .error {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #C30505;
      line-height: 20px;
      margin-top 16px
    }
  }
}


>>> .el-dialog__header {
  padding: 0;
}

>>> .el-dialog__body {
  margin-bottom 0;
  padding-bottom 10px;
}

>>> .el-dialog__footer {
  padding 0
}

.mobileShareCodeBtnWrapper {
  position fixed;
  right 8px;
  top 60px;
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;

  .shareCodeBtn {
    width 54px
  }
}


.shareCodeBtnWrapper {
  position fixed;
  right 1%;
  top 50%;
  transform translateY(-50%)
  width 160px
  cursor pointer;
  display flex;
  justify-content center;
  align-items center;
  flex-direction column
  color #2F54EB
  font-weight bold;

  .shareCodeBtn {
    width 100%;
    margin-top 58px
    transition all 0.5s ease-in-out
  }

  .shareCodeBtn:first-child {
    margin-top 0
  }

  .shareCodeBtn:hover {
    //transform: rotate(360deg);
    width 100%;
  }
  font-size 16px
}

>>> .van-cell
  padding 0

>>> .van-checkbox
  .van-checkbox__icon--checked .van-icon
    background rgba(47, 84, 235, 1);

  .van-checkbox__label
    color: rgba(47, 84, 235, 1);
  align-items flex-start

>>> .van-cell::after
  border-bottom 0 none;

.dialog-footer
  justify-content center
  width 100%
  display flex
  flex-direction column
  justify-content center;
  align-items center
  margin-top 17px

  .textarea
    font-weight lighter !important
    margin-bottom 30px;
    border 1px solid #eee
    width 100%
    height 50px

  .btn-ok
    width: 182px;
    height: 40px;
    background: linear-gradient(90deg, #5C8DF7 0%, #2F54EB 100%);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.13);
    border-radius: 7px;
    color #fff;
>>>.van-popup--center{
  top:36%;
}
</style>
